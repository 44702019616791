.qrPage {
    text-align: center;
    display: flex;
    flex-direction: column;
    height: auto;

    .divToPrint {
        // margin-top: 30px;
        flex-direction: column;
        justify-content: center;
        // position: fixed;
        // width: 600px;
        // top: -150%;
        #divToPrint{
            width: 600px;
            margin: auto;
        }

        .Download {
            background-color: #fff;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border: 2px;
            border-color: black;
            .stopLogo{
               justify-content: center;
                display: flex;
                letter-spacing: 10px;
                img{
                    margin-top: 25px;
                    padding-right: 5px;
                    height: 50px;
                    width: 50px;
                }
            }

            h1 {
                color: rgb(255, 6, 6);
                font-size: 50px;
                line-height: 63px;
            }
            h2{
                color: #ff0202;
                font-size: 35px;
                margin-bottom: 30px;
            }

            .download-qr {
                margin: 20px;
                display: flex;
                justify-content: center;
            }

            p {
                margin: 10px;
                font-weight: 600;
                font-size: 20px;
            }

            .logo {
                margin: 10px;
                display: inline;
                height: 150px;
                width: 130px;
            }
            .footer{
                margin: 0px;
            }
        }

    }

    .qrContent {
        // display: flex;
        // align-items: center;
        // flex-direction: column;
        display: grid;
        grid-template-rows: min-content min-content 1fr;

        .qr-heading {
            padding-right: 10px;
            margin-top: 10px;
            font-size: 30px;
            font-weight: 600;
        }

        .qr-heading1 {
            word-spacing: 5px;
            margin-top: 20px;
            font-size: 24px;
            font-weight: 600;
            padding-bottom: 20px;

        }

        .support-container {
            display: flex;
            width: 100%;
            align-items: center;
            padding-bottom: 40px;
        }

        .qr {

            display: flex;
            justify-content: center;
            align-items: center;
            width: 40%;

        }

        .qr-img {
            height: 250px;
            width: 250px;
            display: flex;
            background-color: white;
            align-items: center;
            justify-content: center;
        }

        .qr-btn {
            .free {
                color: #fff;
                font-size: xx-large;
            }
            padding: 30px 0;
            // height: 15vh;
            justify-content: space-around;

        }

        .btn-wraper {
            // padding-top: 40px;
            width: 20%;
        }

    }
}

.footer {
    background: #fff;
    padding: 8px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: auto;
}
.qrContent.no-print{
    min-height: calc(100vh - 220px);
}
@media print {
    .no-print {
        display: none !important;
        height: 0;
    }
    .divToPrint{
        margin-top: 30px;
    }
}
@media (max-width: 767px) {
    .support-container{
        flex-direction: column;
        align-items: center;
        .qr{
            width: fit-content !important;
        }
        .btn-wraper{
            width: fit-content !important;
            .qr-btn{
                height: fit-content;
                padding-bottom: 10px;
            }
        }
    }
}