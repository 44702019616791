.textField {
    .textFieldContainer {
        display: flex;
        justify-content: flex-end;
        align-items: baseline;
        gap: 30px;

        .inputField {
            background: #151515;
            height: 45px;
            // width: 100%;
            // max-width: 300px;
            width: 100%;
            color: white;
            outline: transparent;
            padding: 20px 20px 20px 10px;
        }

        .lableField {
            font-size: 20px;
            letter-spacing: 1px;
        }

        .inputWrap {
            width: 100%;
            max-width: 300px;

            .mobileInput {
                display: flex;

                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                .CountryCode {
                    background: #151515;
                    height: 45px;
                    // width: 100%;
                    // max-width: 300px;
                    color: white;
                    outline: transparent;
                    padding-left: 20px;
                    width: 60px;
                    margin-right: 19px;
                }
            }
        }
    }

    .error {
        margin: 0;
        font-size: 12px;
        color: #ff0000;
        padding: 4px 0;
        line-height: initial;
        text-align: start;
    }
}

@media (max-width: 576px) {
    .textField {
        .textFieldContainer {
            flex-direction: column;
            gap: 5px;

            .inputWrap {
                max-width: none;
            }
        }
    }
}