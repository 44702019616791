div .main {
    font-family: sans-serif;
    background: #f0f0f0;
    // height: 60px;
    // width: 450px;
    // margin: 0 30px;
}

div .drop-down-container1 {
    margin: 0 auto;
    position: relative;
    z-index: 1;

    & > div:not(.dropdown-top1){
        position: absolute;
        top: 100%;
        left: 0;
    }
}

div .drop-down-header1 {
    box-shadow: 0 2px 3px rgb(0 0 0 / 15%);
    font-size: 1rem;
    color: black;
    font-weight: 600;
    background: #ffffff;
    padding: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 450px;
}

ul .drop-down-list1 {
    padding: 0;
    margin: 0;
    padding-left: 1em;
    background: #ffffff;
    border: 2px solid #e5e5e5;
    box-sizing: border-box;
    color: #3faffa;
    font-size: 1.3rem;
    font-weight: 500;

    width: 450px;

    &:first-child {
        padding-top: 0.8em;
    }
}

.list-item1 {
    cursor: pointer;
    padding: 8px;
    padding-left: 20px;
    background-color: #151515;
    border-left: 0.5px;
    border-right: 0.5px;
    border-bottom: 0.5px;
    border-style: solid;
    border-color: #ffffff;
    color: white;
    text-align: left;
}

.down-btn {
    background-color: white;
    color: black;
    font-weight: 700;
    border-left: 2px;
    border-style: solid;
    padding-left: 26px;
}

.dropdown-top1 {
    display: flex;
    flex-direction: row;
}

.down-btn-img {
    width: 20px;
    height: 20px;
    transform: rotate(180deg);
    background-color: #ffffff;
}

.downArrow-img {
    width: 20px;
    background-color: #ffffff;
    height: 20px;
}

.icon-div {
    position: relative;
    right: 7px;
    padding-top: 14px;
    background-color: white;
}