.forgotPassword-page-2 {
    text-align: center;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 45px);

    .logo-wrapper {
        padding: 15px;

        img {
            width: 150px;
            margin: 0 auto;
            cursor: pointer;
        }
    }

    .margin {
        margin: auto;
    }

    .space-lg {
        margin-bottom: 3rem;
    }

    .description-text{
        color: #fff;
    }
    
    .highlight {
        color: #C11B1B;
    }

    .resend-link {
        // text-decoration: underline;
        // border-bottom: 2px solid white;
        cursor: pointer;
    }

    .otp-input {
        width: 15px;
        border-bottom: 2px solid white;
        background-color: #0A0A0A;
        color: white;
        margin-right: 15px;
        outline: none;
    }

    .otpWarning {
        margin-top: 20px;
        color: red;
        margin-right: 15px;
    }
}