    .blocking-confirmation-dialog {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1000;
        background-color: rgb(0, 0, 0, 0.5);
        display: flex;
        height: 100vh;
        align-items: center;
        justify-content: center;

        .blocking-confirmation {
            height: 125px;
            width: 300px;
            background-color: #FFF;
            box-shadow: 1px 1px 1px 1px #9e9e9e;
            border-radius: 5px;
            position: relative;
            padding: 25px;

            .closeIcon {
                border: 1px solid;
                height: 20px;
                width: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 15px;
                position: absolute;
                right: 10px;
                top: 5px;
            }

            p {
                color: #000;
                font-family: "AktivGrotesk-Medium", Arial, Helvetica, sans-serif;
                text-align: center;
            }

            .confirmation-buttons-wrapper {
                display: flex;
                gap: 30px;
                justify-content: center;
                margin: 10px;

                button {
                    color: #FFF;
                    background: #000;
                    padding: 0 15px;
                }
            }
        }
    }