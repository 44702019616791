.settingsDialog {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    background-color: rgb(0, 0, 0, 0.5);
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;

    .box {
        display: flex;
        padding: 15px 0px 30px 0px;
        height: auto;
        width: 450px;
        border-radius: 12px;
        background-color: aliceblue;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .dialog-heading {
            font-size: 28px;
            font-weight: 700;
            color: black;
        }

        .dialog-heading-icon {
            width: 60px;
            font-size: 28px;
            font-weight: 700;
            color: black;
        }

        .text {
            margin-top: 10px;
            width: 80%;
            font-size: 17px;
            font-weight: 500;
            text-align: left;
            color: black;
            margin-bottom: 10px;

            .dialog-link {
                cursor: pointer;
                color: black;
                font-weight: 700;
                text-decoration: underline;
            }
        }

        .btns {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 80%;

            .dialog-btn {
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                box-shadow: 10px 50px 70px rgb(0, 0, 0, 0.5);
                height: 45px;
                font-weight: 600;
                font-size: 17px;
                width: 40%;
                color: white;
                background-color: black;
            }

            .dialog-btn:hover {
                color: black;
                background-color: #fff;
                border: solid 1px black;
            }
        }

        .space {
            margin-top: 1.4rem;
        }
    }
}