.time-for-action-page {
    text-align: center;
    display: flex;
    flex-direction: column;
    padding: 0 7%;
    align-items: center;

    .heading {
        letter-spacing: 3px;
    }

    .space {
        margin-top: 3rem;
    }

    .description {
        font-size: 1.2rem;

        p {
            color: #fff
        }
    }

    .hidden-container {
        p {
            color: white;
            padding-right: 5px;
        }
        .container{       
            padding: 30px 30px 70px 30px;
       }
    }

    .white-btn {
        background-color: white;
        color: black;
        width: 165px;
        font-weight: 600;
        padding: 10px;
    }

    .description-text {
        font-size: 1rem;
        line-height: 2;
        letter-spacing: 1.4px;
        font-weight: 300;
    }

    .important {
        width: 100%;
        gap: 15px;

        .attachments {
            display: flex;
            width: 100%;

            .after-attach {
                display: flex;

                .close-icon {
                    filter: invert(100%);
                    cursor: pointer;
                }
            }

            .button {
                display: flex;
                justify-content: flex-start;
                width: 50%;

                .btn {
                    margin: 0;
                    font-size: 15px;
                    font-weight: 300;
                }

                .btn:hover {
                    p {
                        color: black;
                    }
                }

                .upload {
                    display: none;
                }
            }

            .text {
                display: flex;
                justify-content: flex-end;
                width: 50%;
                font-weight: 300;
                padding-right: 5%;
            }
        }

        .comments {
            #comments {
                width: 100%;
                background-color: #151515;
                border: 1px solid white;
                padding: 20px;
                height: 70px;
                color: white;
                border-radius: 0;
                outline: none;
                margin-top: 10px;
            }
        }

        .button-ele {
            display: flex;
            justify-content: flex-end;

            .btn {
                margin: 0;
            }
        }
    }
}
.btnDisable{
    opacity: 0.5;
    pointer-events: none;
}

.file-name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width:200px
}

.error {
    font-size: 12px;
    color: #ff0000;
    padding: 4px 0;
    text-align: start;
    width: 200px;
    position: absolute;
    margin-top: 38px;
}        
    

@media (max-width: 590px) {
    .drop-down-container{
        width: 100% !important;
    }
    .attachments{
        flex-direction: column;
        .text{
            width: 100% !important;
            margin-top: 10px;
            p{
                margin-top: 10px;
            }
        }
        .button{
            width: 100% !important;
            .btn{
                max-width: 270px !important;
                width: fit-content;
            }
        }
    }

    .error {
        margin-top: 50px;
    }
}