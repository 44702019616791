.faq-page {
    display: flex;
    flex-direction: column;

    .faq-content {
        display: flex;
        align-items: center;
        flex-direction: column;

        .faq-heading {
            text-align: center;
            font-size: 40px;
            font-weight: 650;
            margin: 20px;
        }

        .faq-container {
            display: flex;
            flex-direction: column;
            gap: 30px;
            width: 100%;
            max-width: 700px;
            padding: 0 40px;
        }
    }
}

.admin {
    .add-faq-btn {
        display: flex;
        margin-top: 40px;
    }
}

.ad-container {
    display: flex;
    justify-content: center;
    // padding: 40px;
    align-items: center;

    // img {
    //   width: 500px;
    // }
  }
  .googleAdimg{
    width: 100%;
    max-width: 728px;
    margin: auto;
  }