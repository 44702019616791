.imgModal {
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    background-color: rgb(0, 0, 0, 0.5);
    display: flex;
    height: 100%;
    width: 100%;
    padding: 0px 20px;
    align-items: center;
    justify-content: center;

    .modal {
        display: flex;
        position: relative;
        height: 100%;
        max-height: 500px;
        width: 100%;
        max-width: 500px;
        background-color: aliceblue;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        box-shadow: 4px 4px 10px rgba(0,0,0,0.3);

        .img-div {
            // width: calc(100% - 140px);
            height: calc(100% - 60px);
            object-fit: cover;
            padding: 10px 70px 30px;
            img{
                object-fit: cover;
                height: 100%;
                margin: auto;
            }
            video{
                object-fit: cover;
                height: 100%;
                margin: auto;
            }
        }
    }
}

.modal-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    background: rgb(0, 0, 0, 0.5);
    height: 100%;
    width: 100vw;
}

.close {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding: 10px 15px 10px 0;

    .dialog-btn-close {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 10px 50px 70px rgb(0, 0, 0, 0.5);
        height: 20px;
        width: 20px;
        border-radius: 50%;
        font-weight: 700;
        font-size: 16px;
        color: rgb(0, 0, 0);
    }
}

@media (max-width: 590px) {
    .imgModal {
        .modal {
           max-width: 400px;
           max-height: 400px;
           .img-div{
                padding: 10px 34px 30px;
           }
        }
    }
}
