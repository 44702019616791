.settings-page {
    text-align: center;
    display: flex;
    flex-direction: column;
    padding: 0 40px;
    align-items: center;

    .heading {
        letter-spacing: 1.5px;
        h1 {
            text-transform: none;
        }
    }

    .google-ad {
        width: 100%;
        max-width: 728px;
        position: relative;
        bottom: 18px;
    }

    .space-lg {
        margin-top: 8rem;
    }

    .main-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .custom-btn {
            height: auto;
            margin: 20px;
            padding: 20px;
            width: 100%;
            max-width: 500px;
            font-size: 1.2rem;
            font-weight: 500;
            background-color: #151515;
        }

        .checkbox {
            margin-top: 30px;
            display: flex;
            flex-direction: row;

            input {
                visibility: hidden;
            }

            label {
                color: white;
                font-size: 1.2rem;
                font-weight: 500;
                letter-spacing: 1px;
            }

            .checkbox-img {
                filter: invert(89%) sepia(54%) saturate(18%) hue-rotate(279deg) brightness(105%) contrast(104%);

                .checked {
                    width: 25px;
                }

                .check {
                    width: 17px;
                    margin-top: 5px;
                    margin-right: 6px;
                }
            }
        }
    }
}