.email-page {
    text-align: center;
    display: flex;
    flex-direction: column;
    padding: 0 7%;
    // min-height: 100vh;
    align-items: baseline;

    h1,
    p,
    a {
        color: #0A0A0A;
    }

    .main-container {
        width: 90%;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .recipients,
    .msg-container,
    .btn-container {
        width: 100%;
        padding: 20px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding-right: 10%;
    }

    .recipients {
        width: 100%;
        padding: 20px;
        align-items: center;

        .text-field {
            width: 90%;

            .warn {
                color: red;
            }

        }

        input {
            width: 80%;
            height: 50px;
            padding: 20px;
            font-size: 1.2rem;
            outline: none;
        }

        label {
            color: white;
            font-size: 1.5rem;
            font-weight: 600;
            margin-right: 30px;
        }
    }

    .msg-container {
        .container-white {
            background-color: white;
            width: 80%;
            padding: 20px;
            text-align: left;
            border-radius: 5px;

            .welcome-msg {
                .heading {
                    font-size: 1.3rem;
                    margin-bottom: 10px;
                }

                .heading-normal-case {
                    padding-bottom: 10px;
                    font-size: 1.3rem;
                }

                .description {
                    padding-left: 4%;
                    line-height: 1.7;
                }
            }

            .referral {
                .heading {
                    margin: 20px 0;
                    font-size: 1.3rem;
                }

                .heading-normal-case {
                    padding-bottom: 10px;
                    padding-top: 5px;
                    font-size: 1.3rem;
                }

                .links {
                    text-align: center;
                    font-size: 1.15rem;

                    .link {

                        line-height: 2.5;
                        border-bottom: 1.5px solid black;
                        font-weight: 500;
                    }
                }
            }
        }
    }

    .btn-container {
        .buttons {
            width: 80%;
            display: flex;
            flex-direction: row;
            justify-content: center;

            .btn {
                width: 150px;
                margin: 30px;
            }
        }
    }

}
@media (max-width:767px) {
    .email-page{
        padding: 0 40px;
        .main-container{
            padding: 20px 0;
            width: 100%;
            form{
                width: 100%;
            }
            .btn-container{
                .buttons{
                    gap: 20px !important;
                    button{
                        margin: 0;
                    }
                }
            }
        }
    }
}
@media (max-width:560px) {
    .email-page{
        .main-container{
            padding: 20px 0;
            .msg-container{
                padding: 20px 0;
                .container-white{
                    width: 100%;
                    .welcome-msg .heading-normal-case{
                        font-size: 1.3rem !important;
                    }
                    .referral .heading-normal-case{
                         font-size: 1.3rem !important;
                    }
                }
            }
            .recipients{
                padding: 0;
                flex-direction: column;
                align-items: flex-start;
                .text-field{
                    width: 100%;
                    input{
                        width: 100%;
                    }
                }
            }
            .btn-container{
                justify-content: center;
                padding: 20px !important;
            }
        }
    }
}