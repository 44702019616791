.user-logCard {
    background-color: #ffffff;
    max-width: 600px;
    color: #000000;
    padding: 10px;
    border-bottom: 5px solid #000000;
    .logCard-row {
        display: grid;
        grid-template-columns: 175px 1fr;
        align-items: center;
        justify-items: start;
        margin-top: 10px;
        margin-bottom: 10px;
        .logCard-value {
            font-size: 13px;
            overflow-wrap: break-word;
            max-width: calc(100vw - 240px);
        }
    }
     .logCard-noRecord {
        justify-items: start;
        margin-top: 10px;
        margin-bottom: 10px;
    }
}
