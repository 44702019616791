.btn-toggle {
    height: 20px;
    border: 1px solid rgb(178 181 186 / 61%);
    border-radius: 20px;
    margin: 0 auto;
    width: 40px;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    background-color: #000;
    .toggle-circle {
        position: absolute;
        height: 14px;
        width: 14px;
        background: #FFF;
        border-radius: 10px;
        top: 0px;
        margin: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        &.active {
            left: 2px;
        }
        &.inactive {
            right: 2px;
        }

    }
}