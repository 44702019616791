.user-management-page {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 94vh;
  height: auto;

  .add-con {
    display: flex;
    width: fit-content;
    padding: 0 20px;
    margin: 0 auto;
    background-color: #c4c4c4;
    height: 80px;
    margin-bottom: 40px;
    color: #fff;
    font-size: 26px;
    align-items: center;
    justify-content: center;
  }

  .account-con {
    display: flex;
    padding-top: 40px;
    margin-bottom: 40px;
    padding-bottom: 80px;

    .btn-wraper {
      margin-top: 100px;

      .acc-btn {
        margin: 50px;

        .acc-btn-btn {
          letter-spacing: 1px;
          height: 50px;
          width: 250px;
        }
      }
    }

    .table-container {
      width: 95%;
      padding-left: 5%;
      overflow: auto;

      .table-header-wraper {
        display: flex;
        // height: 30px;
        align-items: center;
        padding-bottom: 30px;
        padding-left: 16px;

        .account-table-heading {
          font-size: 24px;
          letter-spacing: 1px;
        }

        img {
          cursor: pointer;
          height: 40px;
        }
      }

      .table-wraperr {
        height: auto;
        width: 100%;
        border-radius: 8px;
        background-color: #fff;
        padding-bottom: 8px;
        min-width: 1000px;
        &.active {
          min-width: auto !important;
          .body-wraper {
            height: 110px;
          }
        }

        .head-wraper {
          height: 80px;
          width: 100%;
          border: 1px solid rgb(255, 255, 255);
          border-radius: 8px;
          display: table;
          padding: 20px 20px;
          background-color: #0a0a0a;
          font-weight: 600;
          letter-spacing: 1px;
          text-align: center;

          p {
            color: #fff;
          }
        }

        .body-wraper {
          height: 80px;
          border-bottom: 1px solid #bfbfbf;
          border: 8px;
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: space-around;
          padding: 20px 20px;
          background-color: #ffffff;
          font-weight: 600;
          text-align: center;

          .body-content {
            display: inline-flex;

            img {
              margin-left: 10px;
              height: 26px;
            }
          }

          .attachment-img {
            width: 70px;
            height: 50px;
            margin: auto;
            cursor: pointer;
          }

          .sub-table {
            display: flex;
            flex-direction: column;
            align-items: center;

            .sub-table-body {
              display: flex;
              width: 100%;
              table {
                width: 100%;
                text-align: left;
                tbody {
                  tr {
                    td {
                      &:nth-child(1) {
                        text-align: left;
                        width: 60%;
                      }
                      &:nth-child(2) {
                        text-align: center;
                        width: 20%;
                      }
                      &:nth-child(3) {
                        text-align: center;
                        width: 20%;
                      }
                    }
                  }
                }
              }
            }
          }

          p {
            color: black;
          }
        }

        .body-wraper:hover {
          background-color: #3f3232;
        }
      }

      .pagination {
        display: flex;
        justify-content: flex-end;
        margin-top: 0px;
        margin-bottom: 0%;
        margin-right: 0%;
        align-items: center;

        .acc-page-btn {
          .acc-page-btn-btn {
            background-color: #151515;
            font-size: 16px;
            border: 1px solid white;
            height: 40px;
            color: #ffffff;
            font-weight: 700;
            width: auto;
            padding: 0 12px;
            margin: 0 8px;
          }

          .acc-page-btn-btn-disable {
            background-color: #382a2a;
            font-size: 16px;
            border: 1px solid white;
            height: 40px;
            color: #ffffff;
            font-weight: 700;
            width: auto;
            padding: 0 12px;
            margin: 0 8px;
          }

          .acc-page-btn-btn:hover {
            color: #151515;
            background-color: #fff;
          }
        }

        .page-number {
          cursor: pointer;
          display: flex;
          color: #ffffff;
          background-color: #151515;
          align-items: center;
          margin: 0 5px;
          border: 1px solid #fff;
          border-radius: 50%;
          justify-content: center;
          font-weight: 900;
        }
      }
    }
  }

  .account-heading {
    font-size: 34px;
    font-weight: 500;
  }
}

.elementTd {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

@media (max-width: 1080px) {
  .account-page {
    .account-con {
      padding-top: 50px !important;
      .table-container {
        width: 100% !important;
        padding: 0 25px !important;
        .table-header-wraper {
          padding-bottom: 20px;
          padding-left: 0;
        }
        .table-wraperr {
          width: 100% !important;
        }
        .tables-responsive {
          max-width: calc(100vw - 50px) !important;
          overflow: auto;
          .table-wraperr {
            min-width: 760px;
          }
        }
      }
    }
  }
}
.pagiContainer {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin: 30px 0 80px;
  padding: 0 20px;
  .btn {
    margin: 0;
  }
}
@media (max-width: 590px) {
  .pagiContainer {
    flex-direction: column;
    gap: 20px;
    padding: 0;
    .btn {
      max-width: 260px !important ;
      font-size: 15px !important;
    }
  }
}
