.bubble-msg {
    // height: 190px;
    margin-left: 50px;

    &::after {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -67px;
        height: 20px;
        width: 20px;
        color: mistyrose;
        border-top: 20px solid transparent;
        border-right: 50px solid white;
        border-bottom: 20px solid transparent;
    }

}