.faqdialog-box {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    background-color: rgb(0, 0, 0, 0.5);
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
    letter-spacing: 1px;

    .box {
        display: flex;
        padding: 5px 0px 30px 0px;
        height: auto;
        width: 400px;
        border-radius: 12px;
        background-color: aliceblue;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .dialog-heading {
            font-size: 28px;
            font-weight: 700;
            color: black;
        }

        .dialog-heading-icon {
            width: 66px;
            font-weight: 700;
            color: black;

            position: relative;
            bottom: 30px;
        }

        .text {
            width: 80%;
            font-size: 20px;
            font-weight: 400;
            text-align: center;
            color: #0A0A0A;
            margin-bottom: 20px;

            .dialog-link {
                cursor: pointer;
                color: #0A0A0A;
                font-weight: 700;
                font-size: 1.1rem;
                text-decoration: underline;
                letter-spacing: 0.6px;
            }
        }

        .faq-btn {
            display: flex;
            width: 100%;
            justify-content: space-around;
            padding: 10px 0px 10px 0;

            .dialog-btn {
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                box-shadow: 10px 50px 70px rgb(0, 0, 0, 0.5);
                height: 40px;
                font-weight: 500;
                font-size: 20px;
                width: 40%;
                color: white;
                background-color: black;
            }
        }

        .add-btn {

            padding: 0px 20px 0px 00px;
        }

        .close {
            display: flex;
            width: 100%;
            padding: 10px 15px 10px 0;

            .add-heading {
                font-size: 18px;
                font-weight: 700;
                padding: 0px 0px 0px 10px;
                color: black;
            }

            .dialog-btn-close {
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                box-shadow: 10px 50px 70px rgb(0, 0, 0, 0.5);
                height: 20px;
                width: 20px;
                border-radius: 50%;
                font-weight: 700;
                font-size: 16px;
                color: rgb(0, 0, 0);
            }
        }

        .add-form {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 0 20px 20px 20px;

            .add-form-field {
                width: 100%;
                display: flex;
                padding: 10px 0;

                .add-form-label {
                    width: 30%;
                    font-size: 16px;
                    font-weight: 900;
                    color: black;
                }

                .add-form-input {
                    border: 1px solid black;
                    padding: 0 10px;
                    text-align: center;
                    width: 70%;
                    font-size: 15px;
                    font-weight: 200;
                    height: 50px;

                }
            }
        }

        .add-close {
            justify-content: space-between
        }
    }

    .add-box {
        width: 500px;
        align-items: flex-start;
    }

    .result {
        width: 350px;
    }
}
.dialogueDisabled{
    opacity: 0.5;
    pointer-events: none;
    background-color: transparent !important;
    color: #0A0A0A !important;
    border: 1px solid #0A0A0A;
}