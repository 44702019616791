.adPage {
    height: auto;
    word-spacing: 5px;
    font-size: 20px;

    .submit-btn {
        display: flex;
    }

    .containers {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 30px;
        margin-top: 47px;
        margin-bottom: 47px;
    }

    .heading {
        width: 100%;
        text-align: center;
    }

    .title {
        display: flexbox;
        width: 50%;
    }

    .container1 {
        display: grid;
        grid-template-columns: 1fr 300px;
        width: 70%;
        align-items: center;

        .text {
            width: 100%;
            padding-right: 20px;

            .title {
                display: contents;
                width: 50%;

            }

            p {
                color: #fff;
            }

            .red {
                color: red;
            }
        }

        .files {
            width: 100%;

            .filename {
                border: 1px white;
                border-style: dashed;
                word-wrap: break-word;
                position: relative;
                width: 100%;

                text-decoration: underline;
                height: 100%;
                min-height: 48px;
                color: white;
                font-size: 1rem;
                justify-content: center;
                display: flex;
                align-items: center;
                padding: 5px;

                span {
                    text-decoration: underline;
                }

                .icon {
                    position: absolute;
                    right: -9px;
                    top: -10px;
                    background: white;
                    height: 18px;
                    border-radius: 21px;
                    filter: invert(100%);
                    cursor: pointer;
                }
            }

            .bulk-upload {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                .bulk-action-text {

                    margin: 0;
                    padding: 12px;
                    color: #090a0c;
                    font-size: 14px;
                    font-weight: bold;
                    font-family: "Lato", Arial, Helvetica, sans-serif;
                }

                .custom-file-upload {
                    border: 1px solid #ccc;
                    padding: 0px 12px;
                    cursor: pointer;
                    background-color: #000;
                    box-shadow: 0 1px 5px rgb(0 0 0 / 16%);
                    width: 100%;
                    text-align: center;

                    input[type="file"] {
                        display: none;
                    }

                    .upload-icon {
                        display: inline-flex;
                        padding-top: 8px;
                    }

                    .txt {
                        display: inline-flex;
                        color: #FFF;
                        padding: 8px 0;
                    }
                }
            }
        }
    }

    .container2 {
        display: grid;
        flex-direction: row;
        width: 70%;
        grid-template-columns: 1fr 300px;
        align-items: center;

        .text {
            width: 100%;
            padding-right: 20px;

            p {
                color: #fff;
            }

            .title {
                display: flex;
                width: 100%;
            }
        }

        .link {
            button,
            input,
            optgroup,
            select,
            textarea {
                height: 50px;
                outline: none;
                text-align: center;
                width: 100%;
            }
        }

    }
}
.btnDisable{
    opacity: 0.5;
    pointer-events: none;
}
@media (max-width:990px) {
    .containers{
        padding: 0 60px;
        .container1,
        .container2{
            width: 100%;
        }
    }
}
@media (max-width:769px) {
    .containers{
        padding: 0 40px;
        margin-bottom: 10px !important;
        .container1,
        .container2{
           grid-template-columns: 1fr;
           .files,
           .link{
            margin-top: 20px;
           }
        }
        .container2{
            margin-top: 20px;
        }
    }
    .submit-btn{
        margin-bottom: 20px;
    }
    .heading{
        padding: 0 40px;
    }
}