.admin-landing-page {
    // height: calc(100vh - 40px);
    .admin-page-content {

        padding: 0 100px 50px;
        & > .heading{
            margin-bottom: 25px;
        }

        .table-heading-and-search {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .heading {
                font-size: 20px;
            }

            .search-container {
                .search-input {
                    height: 40px;
                    background: #FFF;
                    border-radius: 5px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin: 10px;
                    padding: 0 10px;

                    input {
                        outline: transparent;
                        margin: 0 10px;
                    }
                }
            }
        }

        .parking-missions {
            text-align: end;
            margin: 10px;
            color: #FFF;
        }
    }

    .user-table {
        background-color: #FFF;
        margin: 12.5px 0;
        overflow: auto;

        table {
            width: 100%;
            text-align: center;
            min-width: 950px;

            thead {
                height: 40px;
                background: #0a0a0a;
                color: #FFF;
                border: 1px solid #FFF;

                .filter {
                    display: flex;
                    align-items: center;
                    padding: 7px;
                    justify-content: center;

                    img {
                        width: 17px;
                        height: 17px;
                    }
                }
            }

            tbody {
                tr {
                    height: 40px;
                }

                tr:nth-child(even) {
                    background-color: #F5F5F5;
                }

                tr:hover {
                    cursor: pointer;
                }

                .switch-field {
                    p {
                        font-weight: 600;
                        background-color: #000;
                        color: #fff;
                        border-radius: 8px;
                        width: 37px;
                        margin: auto;
                    }
                }
            }
        }
    }

    .buttons-and-pagination {
        justify-content: space-between;
        display: flex;
        margin: 15px 0;
        align-items: center;

        .buttons {
            display: flex;
            gap: 50px;
            position: relative;

            button {
                // border: 1px solid #FFF;
                // background-color: #151515;
                padding: 10px 25px;
                margin: 5px;
                font-size: 12px !important;
                font-weight: 100;
                font-family: "Classic-Comic", Arial, Helvetica, sans-serif;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 10px;
                img{
                    width: 18px;
                    aspect-ratio: 1;
                    filter: invert(1);
                    transition: all .25s ease;
                }
                &:hover{
                    img{
                        filter: invert(0);
                    }
                }
                font-family: "Classic-Comic";
            }
        }

        .pagination-wrapper {
            width: 375px;

            p {
                color: #fff
            }
        }
    }

    .view-qr-code-dialog {
        height: 100vh;
        width: 100vw;
        position: fixed;
        /* margin: auto; */
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.6);

        .dialog {
            // height: 42.68vh;
            // width: 20.83vw;
            width: fit-content;
            padding: 10px 30px;
            background: #FFF;
            color: #000;
            border-radius: 10px;
            box-shadow: 4px 4px 10px rgba(0, 0, 0,0.5);
            text-align: center;
            display: flex;
            flex-direction: column;

            .header {
                display: flex;
                justify-content: center;
                padding: 10px 0 15px;
                position: relative;

                h6 {
                    color: #000;
                    text-align: center;
                    font-weight: 700;
                    font-size: 16px;
                }

                .closeIcon {
                    border: 1px solid;
                    height: 20px;
                    width: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 15px;
                    position: absolute;
                    right: 0px;

                    .action-icon {
                        font-size: 12px;
                    }

                }
            }

            .qrcode {
                margin: 0 auto;
            }

            button {
                background-color: #000;
                margin: 20px auto 10px;
                padding: 5px 20px;
                font-weight: 100;
                font-family: "Classic-Comic";
            }
        }
    }

    // .blocking-confirmation-dialog {
    //     height: 100vh;
    //     width: 100vw;
    //     position: fixed;
    //     /* margin: auto; */
    //     top: 0;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     flex-direction: column;
    //     background-color: #9e9e9e99;

    //     .blocking-confirmation {
    //         height: 125px;
    //         width: 300px;
    //         background-color: #FFF;
    //         box-shadow: 1px 1px 1px 1px #9e9e9e;
    //         border-radius: 5px;
    //         position: relative;
    //         padding: 25px;

    //         .closeIcon {
    //             border: 1px solid;
    //             height: 20px;
    //             width: 20px;
    //             display: flex;
    //             justify-content: center;
    //             align-items: center;
    //             border-radius: 15px;
    //             position: absolute;
    //             right: 10px;
    //             top: 5px;

    //             .action-icon {
    //                 font-size: 12px;
    //             }

    //         }

    //         p {
    //             color: #000;
    //             font-family: "AktivGrotesk-Medium";
    //             text-align: center;
    //         }

    //         .confirmation-buttons-wrapper {
    //             display: flex;
    //             gap: 30px;
    //             justify-content: center;
    //             margin: 10px;

    //             button {
    //                 color: #FFF;
    //                 background: #000;
    //                 padding: 0 15px;
    //             }
    //         }
    //     }


    // }

    .send-message-dialog {
        height: 100vh;
        width: 100vw;
        position: fixed;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: rgba(0, 0, 0,0.5);
        z-index: 1;

        .send-message {
            background-color: #FFF;
            padding: 30px 30px 10px 30px;
            width: 400px;
            position: relative;
            border-radius: 3px;

            .closeIcon {
                border: 1px solid;
                height: 20px;
                width: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 15px;
                position: absolute;
                right: 10px;
                top: 5px;

                .action-icon {
                    font-size: 12px;
                }

            }

            p {
                color: #000;
                font-size: 14px;
                font-family: "Classic-Comic";
            }

            input {
                height: 80px;
                width: 100%;
                border: 1px solid #000000;
                outline: transparent;
            }

            .buttons {
                display: flex;
                justify-content: flex-end;
                margin: 10px 0;
                gap: 15px;

                button {
                    background-color: #000;
                    color: #FFF;
                    font-family: "AktivGrotesk-Regular", Arial, Helvetica, sans-serif;
                    font-size: 14px;
                    padding: 5px 10px;
                    border-radius: 2px;
                }
            }

        }
    }
}

.ToPrint {
    position: fixed;
    top: 50000px;
    margin-top: 30px;
    flex-direction: column;
    justify-content: center;

    .Download {
        background-color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 2px;
        border-color: black;

        .text {
            display: flex;
            align-items: center;
            flex-direction: column;
        }

        h1 {
            color: black;
            margin-bottom: 60px;
            font-size: 35px;
        }

        .download-qr {
            display: flex;
            justify-content: center;
        }

        p {
            font-weight: 600;
            font-size: 20px;
        }

        .logo {
            height: 300px;
            width: 300px;
        }
    }

}

.MuiTooltip-tooltip {
    background-color: rgb(0, 0, 0) !important;
    border-radius: 0px !important;
}

.MuiTooltip-popper[data-popper-placement*="bottom"] .css-1k51tf5-MuiTooltip-tooltip {
    margin-top: 2px !important;
    margin-right: 24px;
    width: 199px;
    text-align: center;
}

.css-1d3ml05-MuiPopper-root-MuiTooltip-popper[data-popper-placement*="bottom"] .MuiTooltip-arrow {
    top: 4px !important;
    margin-top: -0.71em;
    left: 182px !important;
    border-width: 15px;
    border-style: solid;
    border-color: black transparent transparent transparent;
    transform: rotate(-26deg) !important;
}

.css-kudwh-MuiTooltip-arrow {
    color: #fff !important;
}

.MuiTooltip-popper .MuiTooltip-tooltip {
    transform-origin: center bottom;
    text-align: center;
    max-width: 211px;
    margin-right: 26px;
    margin-bottom: 3px !important;
}

.MuiTooltip-popper[data-popper-placement*="top"] .MuiTooltip-tooltip::after {
    content: "";
    position: absolute;
    top: 62%;
    right: -2px;
    margin-left: -5px;
    border-width: 15px;
    border-style: solid;
    border-color: black transparent transparent transparent;
    transform: rotate(74deg);
}
.btnWrap{
    position: absolute;
    top: 100%;
    left: 5px;
    display: flex;
    flex-direction: column;
    .btn{
        margin: 0 !important;
    }
}
@media (max-width:1200px) {
    table{
        font-size: 14px;
    }
}
@media (max-width:996px) {
    .buttons-and-pagination{
        flex-direction: column-reverse;
    }
}
@media (max-width:767px) {
    .admin-page-content{
        padding: 0 25px 70px !important;
    }
    .table-heading-and-search{
        flex-direction: column;
        align-items: flex-start !important;
        .search-input,
        .parking-missions{
            margin-left: 0 !important;
        }
    }
    .buttons-and-pagination .buttons{
        flex-direction: column-reverse;
        gap: 0px !important;
    }
}
.ellipuserName{
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    abbr{
    text-decoration: none;
    }
}
.ellipEmail{
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    abbr{
    text-decoration: none;
    }
}
.expottTable{
    width: 446px;
    table-layout: fixed;
    background-color: #000;
    padding: 10px;
    table{
        width: 100%;
        border: 2px solid #fff;
        font-family: Arial, Helvetica, sans-serif;
        thead{
            tr{
                th{
                    background-color: #000;
                    color: #FFF;
                    text-align: center;
                    padding: 3px 10px 10px;
                    font-weight: 600;
                    font-size: 10px;
                    &:first-child{
                        width: 40px;
                    }
                    &:nth-child(2){
                        width: 40px;
                    }
                    &:nth-child(3){
                        width: 40px;
                    }
                    &:last-child{
                        width: 80px;
                    }
                }
            }
        }
        tbody{
            tr{
                background-color: #FFF;
                &:nth-child(even){
                    background-color: #F5F5F5;
                }
                td{
                    text-align: center;
                    padding: 3px 10px 10px;
                    font-size: 10px;
                }
            }
        }
    }
}