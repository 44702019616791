.textField {
    .textFieldContainer {
        display: flex;
        justify-content: flex-end;
        align-items: baseline;
        gap: 30px;

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        .inputField {
            background: #151515;
            height: 45px;
            // width: 100%;
            // max-width: 300px;
            width: 100%;
            color: white;
            outline: transparent;
            padding: 20px;
            font-family: "AktivGrotesk-Medium";
        }

        .lableField {
            font-size: 20px;
            letter-spacing: 1px;
        }

        .inputWrap {
            width: 100%;
            max-width: 300px;
            .visible{
                position: relative;
                svg {
                    position: absolute;
                    right: 20px;
                    top: 12px;
                    filter: contrast(0.1);
                }
            }
        }
    }


    .error {
        margin: 0;
        font-size: 12px;
        color: #ff0000;
        padding: 4px 0;
        line-height: initial;
        text-align: start;
    }
}

@media (max-width: 576px) {
    .textField {
        .textFieldContainer {
            flex-direction: column;
            gap: 5px;

            .inputWrap {
                max-width: none;
            }
        }
    }
}
