.SignUpPage-page {
    text-align: center;
    display: flex;
    flex-direction: column;
    height: auto;
    align-items: center;
    padding: 30px 20px;

    .SignUpPage-page-heading {
        letter-spacing: 2px;
        padding-top: 10px;
        color: #fff;
        font-size: 34px;
    }

    .SignUpPage-page-welcome {
        letter-spacing: 2px;
        font-size: 20px;
        margin: 5px 0;
    }

    .SignUpPage-page-content-1 {
        letter-spacing: 1px;
        font-weight: bolder;
        font-size: 20px;
        margin: 25px 0 25px 0;
    }

    .SignUpPage-page-content-2 {
        letter-spacing: 1px;
        font-weight: bolder;
        font-size: 20px;
        margin: 20px 0 30px 0;
    }

    .logo-wrapper {
        padding: 15px;

        .brand-logo {
            width: 150px;
            margin: 0 auto;
        }
    }

    .social-login-icons {
        display: flex;
        justify-content: center;
        height: 50px;
        // gap: 10px;
        width: 60px;

        img {
            cursor: pointer;
        }
    }

    .form-data {
        flex-direction: column;
        display: flex;
        gap: 30px;
        max-width: 600px;
        margin: auto;

        .notice-field {
            // justify-content: center;
            letter-spacing: 1px;
            color: rgb(206, 33, 33);
            font-size: 20px;
            max-width: 500px;
            margin: 10px auto;
            margin-right: 20px;
        }
    }

    .terms {
        margin-top: 90px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        max-width: 740px;
        color: #fff;

        .lable-field {
            letter-spacing: 1px;
            display: flex;
            font-size: 27px;
            height: 100%;
            width: 100%;
            padding-left: 30px;
            padding-bottom: 10px;
        }

        .text-box {
            display: flex;
            text-align: left;
            padding: 30px 40px;
            border-radius: 8px;
            background-color: #151515;
            height: auto;
            flex-direction: column;
            width: 100%;
            max-width: 100%;

            .text {
                display: flex;
                flex-direction: column;
                margin: auto;
                width: 100%;
                //height: 340px;
                padding-right: 20px;
                overflow: auto;
                font-size: 16px;
                padding: 0;
                a {
                    color: #fff;
                    transition: all 0.25s ease;
                    padding: 5px 20px;
                    border-radius: 5px;
                    cursor: pointer;
                    text-decoration: underline;
                    &:hover {
                        background-color: #808080;
                    }
                }
                .check {
                    display: flex;
                    align-items: center;
                }
            }

            .checkbox {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                margin-top: 20px;
                padding-right: 20px;
                font-family: "Classic-Comic";

                .MuiFormControlLabel-label {
                    font-weight: 600;
                    letter-spacing: 1.5px;
                    font-size: 1.1rem;
                }

                .lable-field-2 {
                    padding-left: 20px;
                    letter-spacing: 0px;
                    font-size: 20px;
                    font-weight: 500;
                }
            }
        }
    }

    .signup {
        margin: 50px auto;
        font-size: 34px !important;
        .signup-btn {
            font-size: 34px !important;
            // height: 60px;
            padding: 0 20px;
            font-weight: 500;
            margin: 15px auto;
            max-width: 230px;
            width: 100%;
            font-size: 34px;
        }
    }

    .end-content {
        display: flex;
        justify-content: center;
        margin-bottom: 60px;

        .lable-field {
            font-size: 22px;
            color: #ffffff;
            max-width: 530px;
            padding: 0;
            text-align: center;
        }
    }

    /* width */
    ::-webkit-scrollbar {
        width: 8px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: white;
        border-radius: 10px;
    }
}
.flexAlignment {
    margin: -30px 0 0px;
}
@media (max-width: 585px) {
    .SignUpPage-page {
        display: block !important;
        padding: 30px 10px !important;
    }
    .signup {
        .signup-btn {
            font-size: 26px !important;
            max-width: 190px;
        }
    }

    .form-data {
        gap: 20px !important;
        p {
            position: relative !important;
        }
    }
    .terms {
        margin-top: 30px !important;
        .text-box {
            padding: 30px 0px !important;
            .checkbox {
                display: block !important;
                margin-left: 100px !important;
            }
        }
    }
}
