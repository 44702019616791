.phone-dialog-box {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 800;
    background-color: rgb(0, 0, 0, 0.5);
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;

    .box {
        display: block;
        padding: 15px 0px 30px 0px;
        max-height: 90vh;
        overflow-y: auto;
        width: 600px;
        border-radius: 12px;
        background-color: aliceblue;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        p, h1 {
            color: black;
        }

        .space {
            margin-top: 1.7rem;
        }

        .header {
            display: flex;
            flex-direction: row;
            width: 100%;

            .heading-text {
                display: flex;
                width: 100%;
                flex-direction: row;
                padding:10px 0 0 25px;
            }
            
            .close1 {
                display: flex;
                width: 100%;
                justify-content: flex-end;
                padding: 0px 10px 10px 0;
                
                .dialog-btn-close-lg {
                    cursor: pointer;
                    box-shadow: 10px 50px 70px rgb(0, 0, 0, 0.5);
                    
                    border-radius: 50%;
                    font-weight: 700;
                    font-size: 16px;
                    color: rgb(0, 0, 0);
                    img {
                        height: 30px;
                        width: 30px;
                    }
                }
            }
        }
        
        .dialog-heading {
            font-size: 18px;
            font-weight: 700;
            color: black;
        }

        .text {
            width: 100%;
            font-size: 20px;
            font-weight: 400;
            text-align: center;
            color: black;
            margin-bottom: 20px;
        }

        .mobile-no-field, .message-field, .button-field {
            width:100%;
            align-items: baseline;
            padding-right: 30px;
        }

        .mobile-no-field {
            display: flex;
            height: auto;
            flex-direction: row;
            align-items: baseline;
            
            .add-input {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0;

                .mobile-input,.is-invalid {
                    width: 93%;
                    margin: 12px 0;
                }
                
            }

            .delete-btn {
                position: relative;
                right: 30px;
                img {
                    width: 20px
                }
            }

            .icon{
                height: inherit;
                display: flex;
                justify-content:baseline;
                align-items: center;

                .add-btn {
                    cursor: pointer;
                    box-shadow: 10px 50px 70px rgb(0, 0, 0, 0.5);
                    height: 25px;
                    width: 28px;
                    border-radius: 50%;
                    font-weight: 700;
                    font-size: 16px;
                    color: rgb(0, 0, 0);
                    margin-left: 10px;
                    padding-bottom: 26px;
                    // position: relative;
                    // left: 245px;
                    // top: -45px;
                }
            }
        }

        .input {
            width: 100%;
            padding:20px 0 20px 10px; 
            position: relative;
            input {
                height: 40px;
                border: 1px solid black;
                outline: none;
                padding: 10px;
            }
            .field{
                display: flex;
                flex-direction: column;
                position: relative;
                .input{
                    span{
                        position: absolute;
                        left: 5px;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                    input{
                        padding-left: 35px;
                    }
                }
            }
            .invalid-feedback{
                color: #ff0000;
                font-size: 12px;
                position: absolute;
                top: 100%;
                left: 50%;
                transform: translate(-50%,-50%);
                white-space: nowrap;
            }

            .msg-text {
                width: 100%;
                border: 1px solid black;
                padding: 10px;
                text-align: left;
                
                a {
                    color: blue;
                    font-size: 13px;
                }

                .ref-code {
                    display: flex;
                    align-items: center;
                    p {
                        font-size: 1.4rem;
                        font-weight: 600;
                    }
                    .dialog-heading {
                        text-indent: 30px;
                    }
                }
            }
        }


        .label {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            padding-right: 10px;
            font-weight: 700;
            font-size: 18px;
        }

        .icon {
            width: 40px;
            position: absolute;
            left: 100%;
            bottom: 40px;
            transform: translateX(-20px);
        }

        .button {
            display: flex;
            padding-right: 20px;
            justify-content: flex-end;
            width: 93%;
            
            .dialog-btn {
                cursor: pointer;
                box-shadow: 10px 50px 70px rgb(0, 0, 0, 0.5);
                height: 45px;
                font-weight: 600;
                font-size: 20px;
                margin: 0;
                width: 50%;
                color: white;
                background-color: black;
            }
        }
    }

    /* width */
// ::-webkit-scrollbar {
//     width: 8px;
//     padding-right: 10px;
//   }
  
//   /* Track */
//   ::-webkit-scrollbar-track {
//     border-radius: 10px;
//   }
  
//   /* Handle */
//   ::-webkit-scrollbar-thumb {
//     background: #888;
//     border-radius: 10px;
//   }
}
@media (max-width:560px) {
    .mobile-no-field{
        flex-direction: column !important;
        margin-top: 20px;
        padding-left: 25px;
        .label{
            justify-content: flex-start !important;
        }
        .input{
            padding: 0 !important;
            .icon{
                bottom: 20px !important;
            }
        }
    }
    .message-field{
        margin-top: 10px;
        padding-left: 25px;
        flex-direction: column !important;
        .label{
            justify-content: flex-start !important;
            margin-bottom: 10px;
        }
        .input{
            padding: 0 !important;
        }
    }
    .button-field{
        margin-top: 20px;
    }
    .phone-dialog-box .box .dialog-heading{
        font-size: 18px !important;
    }
}