.emailverify-dialogbox {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    background-color: rgb(0, 0, 0, 0.5);
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;

    .box {
        display: flex;
        padding: 5px 0px 30px 0px;
        height: auto;
        width: 600px;
        border-radius: 12px;
        background-color: aliceblue;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .dialog-heading {
            font-size: 28px;
            font-weight: 700;
            color: black;
        }

        .dialog-heading-icon {
            width: 66px;
            font-weight: 700;
            color: black;

            position: relative;
            bottom: 30px;
        }

        .text {
            width: 80%;
            font-size: 20px;
            font-weight: 400;
            text-align: center;
            color: #0A0A0A;
            margin-bottom: 20px;
            margin-top: 20px;

            .dialog-link {
                cursor: pointer;
                color: #0A0A0A;
                font-weight: 700;
                font-size: 1.1rem;
                text-decoration: underline;
                letter-spacing: 0.6px;
            }
        }

        .dialog-btn {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 10px 50px 70px rgb(0, 0, 0, 0.5);
            height: 45px;
            font-weight: 600;
            font-size: 20px;
            width: 30%;
            color: white;
            background-color: black;
        }

        .close {
            display: flex;
            width: 100%;
            justify-content: flex-end;
            padding: 10px 15px 10px 0;

            .dialog-btn-close {
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                box-shadow: 10px 50px 70px rgb(0, 0, 0, 0.5);
                height: 20px;
                width: 20px;
                border-radius: 50%;
                font-weight: 700;
                font-size: 16px;
                color: rgb(0, 0, 0);
            }
        }
    }
}