.change-password-page {
  text-align: center;
  display: flex;
  flex-direction: column;

  .logo-wrapper {
    padding: 15px;

    img {
      width: 150px;
      margin: 0 auto;
    }
  }

  .change-input {
    color: white;
    background-color: #0A0A0A;
    padding: 10px 10px 5px 0;
    border-bottom: 2px solid white;
    margin-bottom: 15px;
    width: 300px;
    font-family: "AktivGrotesk-Medium";
    font-size: 1.2rem;
    font-weight: 500;
    outline: none;
  }

  .space-lg {
    margin-bottom: 3rem;
  }

  .warn {
    color: red;
  }

  .eye-icon {
    width: 20px;
    filter: invert(89%) sepia(54%) saturate(18%) hue-rotate(279deg) brightness(105%) contrast(104%);
    position: absolute;
    right: 15px;
    top: 15px;
  }

  .change-form{
    margin: auto;
    width: 100%;
    max-width: 440px;
    padding: 0 25px;
    input{
      width: 100% !important;
    }

    .old-pass{
    text-align: left;
    position: relative;
    }
    
    .new-pass{
      text-align: left;
      position: relative;
    }

    .con-pass{
      text-align: left;
      position: relative;
    }

  }
}
.heading-changePassword{
    font-size: 34px;
    font-weight: 500;
    text-transform: none;
    text-align: center;
}