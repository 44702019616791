.account-page {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 94vh;
  height: auto;

  .table-header-wrapper {
      display: flex;
    }
  
    .table-head-sidebar {
      width: 22%;
    }
  
    .table-account-head {
      width: 75%;
    }
  
    .heading-group {
      display: flex;
      justify-content: space-between;
      width: 100%;
      flex-direction: row-reverse;
      .license-plate-input-group{
        max-width:471px;
      }
      .license-plate-search-field{
        display: flex;
      }
    }

  .table-account-head{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    @media (max-width: 380px) {
      .tooltipAlign{
        > div{
          transform: none !important;
          top: 50px !important;
          right: 0 !important;
          left: auto !important;
          bottom: auto !important;
        }
      }
    }
    .input-field-license-plate {
      width: 100%;
      height: 50px;
      padding: 20px;
      margin-right: 20px;
      background-color: #fff;
      color: #000000;
    }
    .license-plate-btn {
      border: 1px solid #fff;
      background-color: #151515;
      padding: 0 25px;
      height: 50px;
    }
    .license-plate-info {
      cursor: pointer;
      height: 40px;
      transform: rotate(180deg);
    }
    .license-plate-search-head {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      }

      .license-plater-error-text {
        color: red;
      }
      
      .your-account-head {
      display: flex;
      align-items: center;
      }
    .field {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      padding: 20px;
      .input-field {
          width: 300px;
          height: 50px;
          padding: 20px;
          margin-left: 10px;
          color: grey;
      }

      .warn {
          color: red;
      }

      .space {
          margin-top: 2rem;
      }

  }
    & > img {
      cursor: pointer;
      height: 40px;
      transform: rotate(180deg);
     }
    .account-heading {
      font-size: 34px;
      font-weight: 500;
    }
  }

  .add-con {
    display: flex;
    flex-wrap: wrap;
    max-width: 728px;
    width: 100%;
    padding: 0 20px;
    margin: 0 auto;
    background-color: #C4C4C4;
    min-height: 180px;
    margin-bottom: 40px;
    color: #fff;
    font-size: 26px;
    align-items: center;
    justify-content: center;
  }

  .account-con {
    display: flex;
    padding-top: 40px;
    margin-bottom: 40px;
    padding-bottom: 80px;


    .btn-wraper {
      margin-top: 100px;

      .acc-btn {
        margin: 50px;
        .btn {
          letter-spacing: 1px;
          padding: 5px 22px;
          width: 260px;
        }
      }
    }

    .table {
      width: 100%;

      .table-header-wraper {
        display: flex;
        height: 30px;
        align-items: center;
        padding-bottom: 50px;
        padding-left: 16px;
        position: relative;
        @media (max-width: 560px) {
          .tooltipAlign {
            div {
              left: 0 !important;
              top: auto !important;
              max-width: none !important;
              right: auto !important;
              bottom: calc(100% - -20px) !important;
              transform: none !important;
              width: 100% !important;
            }
          }
        }
        .only_header {
          display: flex;
          align-items: center;
        }
        .account-table-heading {
          font-size: 24px;
          letter-spacing: 1px;
        }

        img {
          cursor: pointer;
          height: 40px;
          transform: rotate(180deg);
        }
      }

      .table-wraper {
        height: auto;
        width: 100%;
        border-radius: 8px;
        background-color: #fff;
        padding-bottom: 8px;
        &.active {
          min-width: auto !important;
          .body-wraper {
            height: 110px;
          }
        }

        .head-wraper {
          height: 80px;
          width: 100%;
          border: 1px solid rgb(255, 255, 255);
          border-radius: 8px;
          display: inline-table;
          padding: 20px 20px;
          background-color: #0a0a0a;
          font-weight: 600;
          letter-spacing: 1px;
          text-align: center;

          p {
            color: #fff;
          }
        }

        .body-wraper {
          height: 80px;
          border-bottom: 1px solid #BFBFBF;
          border: 8px;
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: space-around;
          padding: 20px 20px;
          background-color: #ffffff;
          font-weight: 600;
          text-align: center;

          .body-content {
            display: inline-flex;
            align-items: center;
            @media (max-width: 590px) {
              & > div {
                position: relative;
                .tooltipAlign {
                  & > div {
                    max-width: none !important;
                    width: 60vw !important;
                    bottom: calc(100% + 10px) !important;
                    transform: translateX(-50%) !important;
                  }
                }
              }
            }

            img {
              margin-left: 10px;
              height: 26px;
            }
          }
          &:first-child {
            .body-content {
              @media (max-width: 590px) {
                & > div {
                  .tooltipAlign {
                    & > div {
                      & > div {
                        padding: 0 5px !important;
                      }
                    }
                  }
                }
              }
            }
          }

          .attachment-img {
            width: 70px;
            height: 50px;
            margin: auto;
            cursor: pointer;
          }

          .sub-table {
            display: flex;
            flex-direction: column;
            align-items: center;

            .sub-table-body {
              display: flex;
              width: 100%;
              table {
                width: 100%;
                text-align: left;
                tbody {
                  tr {
                    td {
                      &:nth-child(1) {
                        text-align: left;
                        width: 60%;
                      }
                      &:nth-child(2) {
                        text-align: center;
                        width: 20%;
                      }
                      &:nth-child(3) {
                        text-align: center;
                        width: 20%;
                      }
                    }
                  }
                }
              }
            }
          }

          p {
            color: black;
          }
        }

        .body-wraper:hover {
          background-color: #3f3232;
        }
      }

      .pagination {
        display: flex;
        justify-content: flex-end;
        margin-top: 34px;
        margin-bottom: 8%;
        margin-right: 12%;
        align-items: center;

        .acc-page-btn {
          .acc-page-btn-btn {
            background-color: #151515;
            font-size: 16px;
            border: 1px solid white;
            height: 40px;
            color: #ffffff;
            font-weight: 700;
            width: auto;
            padding: 0 12px;
            margin: 0 8px;
          }

          .acc-page-btn-btn-disable {
            background-color: #382a2a;
            font-size: 16px;
            border: 1px solid white;
            height: 40px;
            color: #ffffff;
            font-weight: 700;
            width: auto;
            padding: 0 12px;
            margin: 0 8px;
          }

          .acc-page-btn-btn:hover {
            color: #151515;
            background-color: #fff;
          }
        }

        .page-number {
          cursor: pointer;
          display: flex;
          color: #ffffff;
          background-color: #151515;
          align-items: center;
          margin: 0 5px;
          border: 1px solid #fff;
          border-radius: 50%;
          justify-content: center;
          font-weight: 900;
        }
      }
    }
  }
}

.attachment-img {
  width: 70px;
  height: 50px;
  margin: auto;
  cursor: pointer;
  object-fit: cover;
}

.elementTd {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.tooltipAlign{
  pointer-events: none;
}

@media (max-width: 1080px) {
  .account-page {
    .table-account-head{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    flex-direction: column;
    & > img {
      cursor: pointer;
      height: 40px;
     }
    .account-heading {
      font-size: 34px;
      font-weight: 500;
    }
    .field {
      flex-direction: column;
      align-items: self-start;
      gap: 30px;

      .text-field {
        div {
          display: flex;
          flex-direction: column;
          align-items: self-start;
          gap: 20px;

          .input-field {
            margin: 0;
          }

          button.license-plate-btn {
            border: 1px solid #fff;
            padding: 8px 22px;
            background-color: #151515;
          }
        }
      }
    }
  }
    .account-con {
      flex-direction: column;
      padding-top: 20px !important;
      .btn-wraper {
        margin-top: 0px !important;
        margin-bottom: 50px !important;
        .acc-btn {
          margin: 10px !important;
        }
      }
      .table {
        width: 100% !important;
        padding: 0 20px !important;
        .table-wraper {
          width: 100% !important;
        }
        .table-responsive {
          max-width: calc(100vw - 40px);
          overflow: auto;
          .table-wraper {
            min-width: 760px;
          }
        }
      }
    }
  }
}

@media (min-width: 1080px) {
  .table {
    padding-right: 50px;
  }
  .table-responsive {
    max-width: calc(100vw - 400px);
    overflow: auto;
  }
}

@media (max-width: 560px) {
  .account-con {
    .btn-wraper {
      .acc-btn .btn {
        max-width: 260px !important;
        font-size: 26px !important;
      }
    }
  }
}

@media (max-width:768px){
  .account-page .table-head-sidebar {
    width: 8%;
}
  .heading-group{
    display: block !important;
  }
  .your-account-head{
    padding-top: 20px;
  }
  .account-heading{
    white-space: nowrap;
    width: 100%;
  }

  .account-page .table-account-head .tooltipAlign > div{
    z-index: 99;
  }

  .account-page .table-account-head .license-plate-btn{
    padding: 0 10px;
  }
  
  .account-page .table-account-head {
    width: 85%;
}
}
