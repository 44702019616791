.profile-page {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    .space-lg {
        margin-top: 4rem;
    }

    .profile-edit-btn-group {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .form1 {
        // height: 56vh;
        margin: 1.5px;

        .field {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            padding: 20px;

            .input-field {
                width: 300px;
                height: 50px;
                padding: 20px;
                margin-left: 10px;
                color: grey;
            }

            .warn {
                color: red;
            }

            .space {
                margin-top: 2rem;
            }

        }
    }

    .form {
        // height: 47vh;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .field {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            padding: 20px;

            .warn {
                color: red;
            }

            .input-field2 {
                width: 300px;
                height: 50px;
                padding: 20px;
                margin-left: 10px;
                background-color: white;
                outline: none;
            }

            .btn {
                margin: 0;
                margin-left: 40px;
                width: 200px;
                height: 50px
            }

            .space {
                margin-top: 2rem;
            }

        }
    }


    .googleAd {
        width: 100%;
        max-width: 450px;
        padding: 10px 40px 40px;
    }

    .googleAd1 {
        width: 100%;
        max-width: 450px;
        padding: 10px 40px 40px;
    }
}

.navbar {
    .logo-wrapper {
        // padding: 40px;

        img {
            width: 120px;
        }
    }

    .ad-container {
        // padding: 40px;
        align-items: center;
    }

    .end-icons {
        // padding: 40px;
        align-items: center;

        img {
            width: 40px;
            height: 40px;
            // filter: invert(89%) sepia(54%) saturate(18%) hue-rotate(279deg) brightness(105%) contrast(104%);
        }

        
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.edit-icon {
            img {

                width: 32px !important;
                height: 32px !important;
                // margin-left: 40px;
                filter: invert(89%) sepia(54%) saturate(18%) hue-rotate(279deg) brightness(105%) contrast(104%);
                object-fit: contain;
            }
        }
@media (max-width:590px) {
    .profile-page {
        .form,
        .form1 {
            .field{
                flex-direction: column;
                gap: 10px;
                align-items: flex-start;
                .text-field{
                    input{
                        margin: 0 !important;
                    }
                }
                input{
                    margin: 0 !important;
                }
                .btn{
                    width: 100%;
                    margin: 0;
                }
            }
        }
    }
}