.common-box-ref {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    background-color: rgb(0, 0, 0, 0.5);
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;

    .box {
        display: flex;
        padding: 15px 0px 30px 0px;
        height: auto;
        width: 100%;
        max-width: 550px;
        margin: 0 20px;
        border-radius: 12px;
        background-color: aliceblue;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .dialog-heading {
            font-size: 28px;
            font-weight: 700;
            color: black;
        }

        .text {
            width: 100%;
            font-size: 18px;
            font-weight: 400;
            text-align: center;
            color: black;
            margin-bottom: 20px;
            padding: 0px 10px;
        }

        .license-text {
            font-weight: 700;
        }

        .dialog-btn {
            cursor: pointer;
            box-shadow: 10px 50px 70px rgb(0, 0, 0, 0.5);
            height: 25px;
            font-weight: 600;
            font-size: 20px;
            width: 100px;
            color: white;
            background-color: black;
        }

        .msg-container {
            display: flex;
            flex-direction: row;
            padding: 0 10px;
        }

        .license-plate-input-field {
            width: 300px;
            height: 40px;
            margin-bottom: 5px;
            border: 1px solid grey;
            padding: 0 10px;
        }

        .dailog-subimit-btn {
            height: 40px;
            margin-left: 10px;
        }

        .license-plate-text-container {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .warn {
            color: red;
        }

        .close {
            display: flex;
            width: 100%;
            justify-content: flex-end;
            padding: 10px 15px 10px 0;

            .dialog-btn-close {
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                box-shadow: 10px 50px 70px rgb(0, 0, 0, 0.5);
                height: 20px;
                width: 20px;
                border-radius: 50%;
                font-weight: 700;
                font-size: 16px;
                color: rgb(0, 0, 0);
            }
        }
    }
}
@media (max-width: 590px) {
  .common-box-ref .box .text{
        margin-bottom: 0px;
        width: fit-content;
    }
}