.login-page {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  // min-height: 94vh;
  min-height: calc(100vh - 40px);
  padding: 30px 20px;

  .logo-wrapper {
    padding: 15px;
    margin-top: auto;

    img {
      width: 150px;
      margin: 0 auto;
    }
  }
  .social-login-icons {
    display: flex;
    justify-content: center;
    height: 50px;
    // gap: 10px;

    img {
        cursor: pointer;
    }
}
  .loginSec {
    margin-bottom: auto;
    width: 100%;
  }

  .login-label {
    color: white;
    font-size: 1.2rem;
    font-weight: 300;
    font-family: "AktivGrotesk-Regular", Arial, Helvetica, sans-serif;
  }

  .login-form {
    .or {
      display: flex;
      justify-content: center;
      align-items: center;

      p {
        color: #fff;
      }

      .or-option {
        border: 1px solid #fff;
        width: 40px;
        height: 40px;
        padding: 10px;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        text-transform: uppercase;
      }
    }
    .btn {
      max-width: 230px;
      width: 100%;
      font-size: 34px;
    }
  }

  .login-input {
    background-color: #53535326;
    height: 40px;
    width: 250px;
    margin: 0 20px;
    outline: none;
    padding: 20px;
    color: white;
  }

  .check-label {
    margin-left: 15px;
  }

  .space-lg {
    margin-top: 2rem;
  }

  .space {
    margin-top: 1rem;
  }

  #forgot-pass {
    font-size: 12px;
  }

  .main-container {
    max-width: 500px;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .label {
    display: flex;
    justify-content: flex-end;
    padding-top: 10px;
  }

  .forgot-pass {
    display: flex;
    justify-content: flex-end;
    padding-right: 13px;
    // padding-top: 5px;
  }

  .forgot-label {
    cursor: pointer;
    color: white;
  }
}

@media (max-width: 552px) {
  .login-page {
    min-height: calc(100vh - 64px);
  }
  .btn {
    font-size: 26px !important;
  }
  .login-form {
    .or {
      .or-option {
        font-size: 26px;
        height: 50px !important;
        width: 50px !important;
      }
    }
  }
}
.space-reduce {
  margin-bottom: 10px;
}
.text-xl {
  margin-bottom: 10px;
}
.orStyles {
  letter-spacing: 1px;
  font-weight: bolder;
  font-size: 20px;
  margin: 20px 0 30px 0;
}
