.forgot2-box-emailref {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    background-color: rgb(0, 0, 0, 0.5);
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;

    .box2 {
        display: flex;
        padding: 15px 0px 30px 0px;
        height: auto;
        width: 382px;
        border-radius: 12px;
        background-color: aliceblue;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .dialog-heading {
            font-size: 28px;
            font-weight: 700;
            color: black;
        }

        .text {
            width: 90%;
            font-size: 20px;
            font-weight: 400;
            text-align: center;
            color: black;
            margin-bottom: 20px;
        }


        .delete-btn {
            cursor: pointer;
            box-shadow: 10px 50px 70px rgb(0, 0, 0, 0.5);
            height: 45px;
            font-weight: 600;
            font-size: 20px;
            // width: 30%;
            min-width: 100px;
            color: white;
            background-color: black;
        }
        .delete-buttons{
            display: flex;
            gap: 20px;
        }
        .close {
            display: flex;
            width: 100%;
            justify-content: flex-end;
            padding-right: 15px;
            padding-bottom: 10px;

            .dialog-btn-close {
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                box-shadow: 10px 50px 70px rgb(0, 0, 0, 0.5);
                height: 20px;
                width: 20px;
                border-radius: 50%;
                font-weight: 700;
                font-size: 16px;
                border: 2px solid rgb(0, 0, 0);
                color: rgb(0, 0, 0);
            }
        }
    }
}