.landing-page {
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 30px 15px;

  .logo-wrapper {
    padding: 15px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;

    .logo {
      width: 150px;
      margin: auto;

      .logoLink{
        cursor: auto;
      }
    }

    .right-side-icons {
      display: flex;
      justify-content: flex-end;

      img {
        height: 40px;
        width: 40px;
        object-fit: cover;
        margin: 0 10px;
      }

      .menu-icon {
        cursor: pointer;
      }

      .setting-icon {
        cursor: pointer;
      }
    }
  }

  .margTop {
    margin-top: 50px;

    .description-text {
      color: #fff;
    }
  }

  .container {
    margin-top: 50px;
    gap: 25px;
    padding: 50px 30px;

    .description-text {
      max-width: 550px;
      margin: auto;
    }
    .sign-log {
      display: flex;
      font-size: 34px;
      font-weight: 500;
      text-transform: uppercase;
      text-align: center;
      .or {
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          color: #fff;
        }

        .or-option {
          border: 1px solid #fff;
          width: 60px;
          height: 60px;
          padding: 10px;
          border-radius: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0;
          text-transform: uppercase;
        }
      }
    }

    .marg30 {
      margin: 30px auto 0;
      max-width: 400px;
    }
  }
}

@media (max-width: 768px) {
  .landing-page {
    h1 {
      font-size: 26px;
    }

    .logo-wrapper {
      .right-side-icons {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-end;
      }
    }
  }
  .container {
    .sign-log {
      display: flex;
      flex-direction: column;
    }
  }
}
