.contact-page {
    text-align: center;
    display: flex;
    flex-direction: column;
    padding: 0 7%;
    // min-height: 100vh;
    align-items: center;

    .heading {
        h1 {
            text-transform: none;
        }
    }
    
    .main-container {
        width: 850px;
    }

    .label {
        display: flex;

        justify-content: flex-end;
        // padding-top: 10px;
        align-items: baseline;
        &:has(label[for="message"]){
            align-self: start;
            padding-top: 8px;
        }
    }

    .input {
        display: flex;
        justify-content: flex-start;
        position: relative;


        .warn {
            color: red;
            bottom: 0;
            left: 0;
            transform: translateY(100%);
            position: absolute;
        }
    }

    .space-lg {
        margin-top: 4rem;
    }

    .space {
        margin-top: 2.2rem;
    }

    .contact-label {
        color: white;
        font-size: 1.4rem;
        max-width: 225px;
    }

    .contact-input {
        background-color: white;
        color: #0A0A0A;
        height: 60px;
        width: 450px;
        // margin: 0 30px;
        padding: 20px;
        line-height: 1.7;
        outline: none;
    }

    .drop-down {
        height: 60px;
        width: 450px;
        margin: 0 30px;
        text-align: center;
        font-weight: 700;
    }

    .dropdown-label {
        text-align: right;
    }

    #message {
        height: 170px;
    }

    .option {
        height: 30px;
    }

    .btn {
        &:first-child {
            margin: 0 25px 0 0px;
        }

        &:last-child {
            margin: 0 0px 0 25px;
        }
    }

    .white-btn {
        background-color: white;
        color: #0A0A0A;
        font-weight: 600;
    }

    .google-ad {
        max-width: 728px;
        width: 100%;
        margin: auto;
    }

    // .dropdown-div {
    //     padding-left: 30px;
    //     padding-right: 30px;
    // }

}

.gridAlign {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: end;
    gap: 30px;
    padding-right: 30px;
    position: relative;
    right: 120px;
    align-items: center;
}
@media (max-width: 960px) {
    .contact-page{
        form{
            width: 100%;
            .main-container{
                width: 100%;
                max-width: 500px;
                margin: auto;
                .gridAlign{
                    grid-template-columns: 1fr;
                    right: 0;
                    padding-right: 0;
                    justify-content: start;
                    .label{
                        justify-content: flex-start;
                    }
                    .input{
                        & > div{
                            width: 100%;
                            textarea,
                            input{
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 590px) {
    .contact-page{
        form{
            .main-container{
                .gridAlign{
                    gap: 10px;
                    .dropdown-label{
                        max-width: 300px;
                        text-align: left;
                        label{
                            position: unset !important;
                        }
                    }
                    .dropdown-div{
                        .main{
                            background-color: #FFF;
                            .drop-down-header1{
                                width: 100%;
                            }
                            .icon-div{
                                padding: 0;
                                display: flex;
                                align-items: center;
                            }
                        }
                    }
                    .input{
                        flex-direction: column;
                        gap: 20px;
                        button{
                            margin: auto;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}