.forgot-box-ref {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    background-color: rgb(0, 0, 0, 0.5);
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;

    .box {
        display: flex;
        padding: 15px 0px 30px 0px;
        height: auto;
        width: 100%;
        max-width: 550px;
        margin: 0 20px;
        border-radius: 12px;
        background-color: aliceblue;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .dialog-heading {
            font-size: 28px;
            font-weight: 700;
            color: black;
        }

        .text {
            width: 100%;
            font-size: 20px;
            font-weight: 400;
            text-align: center;
            color: black;
            margin-bottom: 20px;
            // margin-left: 18px;
            strong {
                display: inline-flex;
                align-items: center;
                position: relative;
                span {
                    position: absolute;
                    left: 100%;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }

        .send-text {
            font-weight: 700;
        }

        .msg-container {
            display: flex;
            flex-direction: row;
            padding: 0 10px;
        }

        .btn-container {
            gap: 15px;
            display: flex;
            flex-direction: row;
            justify-content: center;
        }

        .dialog-btn {
            cursor: pointer;
            box-shadow: 10px 50px 70px rgb(0, 0, 0, 0.5);
            height: 45px;
            font-weight: 600;
            font-size: 20px;
            width: 200px;
            color: white;
            background-color: black;
        }

        .ctc {
            width: 20px;
            height: 25px;
            margin: 0px 5px 0px 10px;
        }

        .close {
            display: flex;
            width: 100%;
            justify-content: flex-end;
            padding: 10px 15px 10px 0;

            .dialog-btn-close {
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                box-shadow: 10px 50px 70px rgb(0, 0, 0, 0.5);
                height: 20px;
                width: 20px;
                border-radius: 50%;
                font-weight: 700;
                font-size: 16px;
                color: rgb(0, 0, 0);
            }
        }

        .copy-text-show {
            color: white;
            width: 62px;
            font-size: 60%;
            padding-top: 2%;
            background: black;
            height: 34px;
            opacity: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 2px;
            // margin-left: 13px;
        }

        .copy-text-hide {
            color: white;
            opacity: 0;
            width: 62px;
            font-size: 60%;
            padding-top: 2%;
            height: 34px;
            // margin-left: 13px;
        }
    }
}
@media (max-width: 590px) {
    .text{
        strong{
            display: flex !important;
            position: relative;
            width: fit-content;
            margin: 5px auto 0;
            .copy-text-show,
            .copy-text-hide{
                position: absolute;
                right: 0;
                top: 50%;
                transform: translate(100%,-50%);
            }
        }
    }
    .btn-container{
        flex-direction: column !important;
        align-items: center;
        gap: 15px;
        width: 100%;
        button {
            margin: 0 !important;
            width: 100% !important;
            max-width: 250px;
        }
    }
}