

.downloadIcon {
    height: 150px;
}

.relative{
    position: relative;
    width: 100%;
    .pdfDownload {
        display: flex;
        justify-content: center;
        align-items: center; 
        color: black;
        cursor: pointer;   
        .font {
            color: black;
            word-break: break-all;
            margin-top: 10px;
            cursor: pointer;

        }
    }
    .icon{
        position: absolute;
        top: 10px;
        right: 50px;
        cursor: pointer;
    }
    
}

