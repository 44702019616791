.tfa-modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    background-color: rgb(0, 0, 0, 0.5);
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
    .modal-box {
        display: flex;
        padding: 10px 0px 30px 0px;
        height: auto;
        width: 650px;
        border-radius: 12px;
        background-color: aliceblue;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .text {
            color: #151515;
            position: relative;
            bottom: 15px;
            font-size: 1.1rem;
            font-weight: 500;
        }

        .close {
            display: flex;
            width: 100%;
            justify-content: flex-end;
            padding: 10px 15px 10px 0;

            .dialog-btn-close {
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                box-shadow: 10px 50px 70px rgb(0, 0, 0, 0.5);
                height: 20px;
                width: 20px;
                border-radius: 50%;
                font-weight: 700;
                font-size: 16px;
                color: rgb(0, 0, 0);
            }
        }

        .space {
            margin-top: 1.7rem;
        }

        .buttons {
            display: flex;
            width: 100%;
            justify-content: space-around;
            
            .btn {
                background-color: #151515;
                color: white;
                width: 25%;
                margin: 0;
                box-shadow: 6.00047px 6.00047px 30px rgba(0, 0, 0, 0.25);
                border-color: #151515;
            }
            
        }

        .text-lg {
            color: #151515;
            font-size: 1.2rem;

            .link {
                border-bottom: 1px solid #151515;
                color: #151515;
                cursor: pointer;
            }
        }
    }
}

@media (max-width: 585px) {
    .tfa-modal {
        padding: 0px 10px;
        .modal-box {
            .text{
                padding: 0px 35px;
            }
        }
    }
    .buttons {
        gap: 20px;
        .btn {
            max-width: 150px;
            width: 100% !important;
        }
    }
    .text-lg{
        padding: 0px 5px;
    }
}
