.pagination {
    display: flex;
    justify-content: flex-end;
    margin-top: 34px;
    margin-bottom: 8%;
    margin-right: unset !important;
    align-items: center;

    .acc-page-btn {
      .acc-page-btn-btn {
        background-color: #151515;
        font-size: 16px;
        border: 1px solid white;
        height: 40px;
        color: #ffffff;
        font-weight: 700;
        width: auto;
        padding: 0 12px;
        margin: 0 8px;
      }

      .acc-page-btn-btn-disable {
        background-color: #382a2a;
        font-size: 16px;
        border: 1px solid white;
        height: 40px;
        color: #ffffff;
        font-weight: 700;
        width: auto;
        padding: 0 12px;
        margin: 0 8px;
      }

      .acc-page-btn-btn:hover {
        color: #151515;
        background-color: #fff;
      }
       button {
      display: flex;
      align-items: center;
    }
    }

    .page-number {
       aspect-ratio: 1;
      cursor: pointer;
      display: flex;
      color: #ffffff;
      background-color: #151515;
      align-items: center;
      margin: 0 5px;
      border: 1px solid #fff;
      border-radius: 50%;
      justify-content: center;
      font-weight: 900;
    }
  }