.footer {
  background: #fff;
  // height: 45px;
  padding: 8px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: auto;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

* {
  font-family: "Classic-Comic";
}

@font-face {
  font-family: "AktivGrotesk-Black";
  src: url("./fonts/AktivGrotesk-Black.woff");
}

@font-face {
  font-family: "AktivGrotesk-Light";
  src: url("./fonts/AktivGrotesk-Light.woff");
}

@font-face {
  font-family: "AktivGrotesk-Medium";
  src: url("./fonts/AktivGrotesk-Medium.woff");
}

@font-face {
  font-family: "AktivGrotesk-Regular";
  src: url("./fonts/AktivGrotesk-Regular.woff");
}

@font-face {
  font-family: "AktivGrotesk-Bold";
  src: url("./fonts/AktivGrotesk-Bold.woff");
}

@font-face {
  font-family: "Classic-Comic";
  src: url("./fonts/Classic-Comic.woff");
}

h1 {
  font-family: "Classic-Comic";
}

h3 h4 h6 {
  font-family: "Classic-Comic";
}

p {
  font-family: "Classic-Comic";
}

.heading {
  font-size: 34px;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
}

.flex {
  display: flex;
}

.action-icon {
  cursor: pointer;
}

.hide-element {
  display: none;
}

@media (max-width: 580px) {
  h1 {
    font-size: 26px !important;
  }
  .heading {
    font-size: 26px !important;
  }
}

@media (max-width: 590px) {
  .btn {
    font-size: 26px;
    max-width: 190px;
  }
}
