.supportPage {
    text-align: center;
    display: flex;
    flex-direction: column;
    height: auto;

    .supportContent {
        display: flex;
        align-items: center;
        flex-direction: column;

        .support-heading {
            text-align: center;
            padding-bottom: 50px;
            font-size: 34px;
            letter-spacing: 2px;
            font-weight: 650;
            justify-content: space-around;

        }

        .support-container {
            display: flex;
            flex-direction: column;
            text-align: left;
            letter-spacing: 1px;

            .h4 {
                align-items: center;
                font-size: 22px;
                justify-content: space-around;
                padding-bottom: 20px;
            }

            p {
                color: #fff;
            }

            .cont {
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                width: 100%;
                padding-left: 20px;
                gap: 30px;
                font-size: 18px;
            }

            .a {
                text-decoration-color: aliceblue;
            }

            u {
                cursor: pointer;
            }
        }
    }
}
@media (max-width:767px) {
    .supportContent{
        padding: 0 40px 40px;
    }
}