.send-message-dialog {
    // height: 100vh;
    // width: 100vw;
    position: fixed;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: rgba(0,0,0,0.5);
    padding: 20px;
    z-index: 1;
    // left: 0;

    .send-message {
        background-color: #FFF;
        padding: 30px 30px 10px 30px;
        width: 100% !important;
        max-width: 550px;
        position: relative;
        border-radius: 3px;

        .closeIcon {
            border: 1px solid;
            height: 20px;
            width: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 15px;
            position: absolute;
            right: 10px;
            top: 5px;

            .action-icon {
                font-size: 12px;
            }

        }

        p {
            color: #000;
            font-size: 14px;
            font-family: "AktivGrotesk-Medium", Arial, Helvetica, sans-serif;
        }

        textarea {
            height: 120px;
            width: 100%;
            border: 1px solid #000000;
            outline: transparent;
            font-family: "AktivGrotesk-Medium";
        }

        .buttons {
            display: flex;
            justify-content: flex-end;
            margin: 10px 0;
            gap: 15px;

            button {
                background-color: #000;
                color: #FFF;
                font-family: "AktivGrotesk-Regular", Arial, Helvetica, sans-serif;
                font-size: 14px;
                padding: 5px 10px;
                border-radius: 2px;
            }
        }

    }
}
@media (max-width:590px) {
    .send-message-dialog{
        .send-message{
            .buttons{
                flex-direction: row;
                gap: 10px !important;
            }
        }
    }
}