@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
    font-family: "Classic-Comic";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0A0A0A;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.heading {
  color: #fff;
  font-size: 30px;
}

.uppercase {
  text-transform: uppercase;
}
.heading-space {
  margin: 30px 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
button {
  color: #fff;
}

.container {
  background-color: #53535326;
  max-width: 800px;
  margin: 20px auto;
  padding: 30px;
  gap: 45px;
  display: flex;
  flex-direction: column;
}

.btn {
  border: 1px solid #fff;
  padding: 5px 20px;;
  font-weight: 500;
  margin: 15px auto;
  width: 100%;
  max-width: 230px;
  background-color: #151515;
  color: #fff;  
}

.btn:hover{
  background: #fff;
  color: #151515;
  opacity: 95%;
}
.input-field {
  background: #151515;
  height: 5vh;
  width: 20%;
  color: white;
  outline: transparent;
}

.lable-field {
  padding-right: 20px;
  font-size: 20px;
}


/* Loader Styles */
.loader{
  overflow: auto;
  overscroll-behavior: contain;
}
.loader::-webkit-scrollbar{
  display: none;
}
.loader::after{
  content: '';
  height: 101vh;
  opacity: 0;
}
.loader span{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}