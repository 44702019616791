.navbar {
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  .head-container{
    padding: 30px;
  }

  .logo-wrapper {
    // padding: 40px;

    img {
      cursor: pointer;
      width: 120px;
    }
  }

  .ad-container {
    display: flex;
    justify-content: center;
    // padding: 40px;
    align-items: center;
  }

  .end-icon {
    // padding: 40px;
    align-items: center;
    gap: 30px;
    grid-row: 1/2;
    grid-column: 2/3;

    // img {
    //   width: 40px;
    //   height: 40px;
    // }
  }

  .end-icons {
    // padding: 40px;
    align-items: center;
    gap: 30px;

    img {
      width: 40px;
      height: 40px;
      // margin-left: 40px;
      // filter: invert(89%) sepia(54%) saturate(18%) hue-rotate(279deg) brightness(105%) contrast(104%);
    }
  }
}

@media (max-width: 767px) {
  .navbar {
    .head-container {
      display: grid;
      grid-template-columns: 1fr 1fr;

      .logo-wrapper {
        grid-column: 1/2;
      }

      .ad-container {
        grid-column: 1/3;
        img{
          margin-top: 20px;
        }
      }

      .end-icons {
        grid-column: 2/3;
        grid-row: 1/2;
      }
    }
    .logo-wrapper{
      img{
        width: 80px !important;
      }
    }
  }
}
.adimg{
  max-width: 728px;
  width: 100%;
  min-height: 180px;
}
// .imagInvert{
//   filter: invert(1);
//   transform: scale(1.05);
// }

@media (max-width: 560px) {
  .navbar{
    .head-container{
      .logo-wrapper{
        position: relative;
        width: fit-content;
        & > div{
          .tooltipAlign{
            & > div{
              transform: none !important;
              inset: 0 !important;
              top: 0 !important;
              height: fit-content;
              width: 60vw;
              left: calc(100% + 10px) !important;
              padding: 0 !important;
              ul{
                line-height: 20px;
                &::after{
                  top: 40%;
                  left: -37px;
                  border-top: 10px solid transparent;
                  border-right: 15px solid white;
                  border-bottom: 10px solid transparent;
                }
              }
            }
          }
        }
      }
    }
  }
}