.incident-details-page {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;

    p{
      color: white;
    }
  .logo-wrapper {
    padding: 15px;
    cursor: pointer;

    img {
      width: 150px;
      margin: 0 auto;
    }
  }

  .heading-text {
    font-size: 1.5rem;
    font-weight: 700;
    letter-spacing: 2.5px;
  }

  .space {
    margin-top: 7rem;
  }

  .main-container {
    width: 70%;

    .white-line {
        border-top: 1px;
        border-style:  solid;
        border-color:  white;
        width: 100%;
      }
  }

  .field-container {
    display: flex;
    flex-direction: row;

    .label-text, .value-text {
        font-size: 1.2rem;
        font-weight: 600;
    }
    .attachment-img {
      width: 70px;
      height: 50px;
      margin: auto;
      cursor: pointer;
    }
    .label{
        text-align: left;
        padding: 20px;
        padding-left: 5%;

        .label-text {
            font-size: 1.3rem;
        }
    }

    .value {
        display: flex;
        justify-content: flex-end;
        padding: 20px;
        padding-right: 5%;

        .value-text {
            font-weight: 400;
            text-align: right;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 500px;
            width: 100%;
             white-space: break-spaces;
        }
    }
  }
}
.firstCapse::first-letter {
  text-transform: uppercase;
}
@media (max-width: 560px) {
  .incident-details-page{
    .main-container{
      width: 100%;
      padding: 0 20px;
    }
  }
  .value,
  .label {
    padding: 20px 0px !important;
  }
}
