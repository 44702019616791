.pdfViewr {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    background-color: rgb(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    .box {
        position: relative;
        .close {
            position: fixed;
            z-index: 1;
            right: 20px;
            top: 20px;
            padding: 3px;
            border-radius: 5px;
            width: fit-content;
            background-color: #fff;
            .dialog-btn-close {
                width: 30px;
                aspect-ratio: 1;
                height: auto;
            }
        }
        .pdf-body {
            width: 95vw;
            height: 1056px;
            max-height: calc(100vh - 120px);
            max-width: 1145px;
        }
    }
}
