.faq-box-con {
    display: flex;
    width: 100%;
    align-items: flex-start;
    &.admin-con{
        display: grid;
        grid-template-columns: 1fr 120px;
        .admin-tools{
            width: 100%;
            img{
                margin: auto !important;
            }
        }
    }
    .faq-con {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;

        .title {
            width: 100%;
            letter-spacing: 1px;
            display: flex;
            align-items: center;
            padding: 0 10px 0 25px;
            cursor: pointer;
            padding-bottom: 10px;
            font-weight: 600;
            font-size: 20px;
            justify-content: space-between;
        }

        .description {
            background-color: #151515;
            padding: 20px 50px 20px;
            letter-spacing: 2px;
            font-size: 20px;
            width: 100%;
            display: flex;
            text-align: left;
            transition: height 200ms;
        }

    }

    .admin-tools{
        display: flex;
        width: 10%;
        .delete-ic{
            cursor: pointer;
            width: 30px;
            height: 30px;
            margin-left: 30px;
        }
        .edit-ic{
            cursor: pointer;
            width: 25px;
            height: 25px;
            filter: invert(100%);
            margin-left: 50px;
            color: white;
        }
        &.active{
            padding-top: 50px;
        }
    }
}
@media (max-width: 560px) {
    .faq-box-con{
        &.admin-con{
            grid-template-columns: 1fr 80px;
            gap: 10px;
        }
        .faq-con{
            .title{
                padding: 0 0 10px;
                overflow-wrap: anywhere;
            }
            .description{
                padding: 10px 30px;
            }
        }
    }
}