.forgotPassword-page {
    text-align: center;
    display: flex;
    flex-direction: column;
    // height: calc(100vh - 45px);
    gap: 40px;

    .logo-wrapper {
        padding: 15px;

        img {
            width: 150px;
            margin: 0 auto;
        }
    }

    .btn {
        margin-top: 100px;
    }

    .forget-email {
        max-width: 400px;
        margin: auto;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .field {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 1%;

        .input-field {
            padding: 20px;
        }
    }
}