.forgot-password-page3 {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 94vh;

  .logo-wrapper {
    padding: 15px;

    img {
      width: 150px;
      margin: 0 auto;
    }
  }

  .change-input {
    color: white;
    background-color: #0A0A0A;
    padding: 10px 10px 5px 0;
    border-bottom: 2px solid white;
    margin-bottom: 30px;
    width: 300px;
    font-family: "AktivGrotesk-Medium";
    font-size: 1.2rem;
    font-weight: 500;
    outline: none;
  }

  .space-lg {
    margin-bottom: 3rem;
  }

  .eye-icon {
    width: 20px;
    filter: invert(89%) sepia(54%) saturate(18%) hue-rotate(279deg) brightness(105%) contrast(104%);
    position: absolute;
    position: relative;
    right: 30px;
  }

  .close-btn {
    border: none;
    position: absolute;
    right: 5px;
    top: 5px;
    border-radius: 40%;
  }

  .cls-img {
    width: 20px;
  }

  .modal {
    width: 300px;
  }

  .warn {
    color: red;
  }

  .modal-text {
    color: black;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 30px;
    text-align: center;
  }
}