.emailVerify-page {
    text-align: center;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 45px);
    gap: 40px;

    .logo-wrapper {
        padding: 15px;

        img {
            width: 150px;
            margin: 0 auto;
        }
    }

    .verify-email {
        position: absolute;
        top: 168px;
        font-size: 30px;
        font-weight: 600;
        max-width: 400px;
        margin: 47px 325px;
        gap: 20px;
        display: contents;
    }
}