.offer-page {
    text-align: center;
    display: flex;
    flex-direction: column;
    padding: 0 7%;
    align-items: center;

    .heading {
        letter-spacing: 3px;
    }

    .space {
        margin-top: 3rem;
    }

    .description {
        font-size: 1.2rem;

        p {
            color: #fff
        }
    }

    .hidden-container {
        p {
            color: white;
            padding-right: 5px;
        }

        .containers {
            background-color: #f0f0f0;
            max-width: 800px;
            margin: 20px auto;
            width: 559px;
            height: 274px;
            border-radius: 50px;

            .items {
                padding: 40px;
                height: 100%;
                label{
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    flex-direction: column;
                }
                .upload{
                    display: none;
                }
                .close {
                    display: flex;
                    justify-content: center;
                    padding: 10px 15px 10px 0;

                    .btn-close {
                        cursor: pointer;

                        img {
                            height: 56px;
                            max-width: 66px;
                        }
                    }

                }

                .description {
                    color: black;
                    font-weight: bolder;
                    letter-spacing: 2px;
                }

                .or {
                    color: black;
                }

                .btns {
                    height: 40px;
                    border-radius: 15px;
                    padding: 0 20px;
                    font-weight: 500;
                    margin: 15px auto;
                    width: 169px;
                    background-color: #151515;
                }
            }
        }
    }
}
.flex-center{
    display: flex;
    align-items: center;
    justify-content: center;
}
@media (max-width: 590px) {
    .heading{
        padding: 0;
        h1{
            font-size: 24px;
        }
    }
    .dropdown{
        width: 100%;
        .main{
            .drop-down-container1{
                .dropdown-top1{
                    .drop-down-header1{
                        width: 100%;
                    }
                }
            }
        }
    }
    .hidden-container{
        .containers{
            width: 100% !important;
            border-radius: 25px !important;
            margin: 0 !important;
            .items{
                label{
                    button{
                        height: auto !important;
                        padding: 10px 20px !important;
                    }
                }
            }
        }
    }
    .offer-page{
        padding-bottom: 50px;
        .space{
           margin-top: 1.5rem;
        }
    }
}